import { StateCreator, StoreMutatorIdentifier } from "zustand";

type Logger = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>;

type LoggerImpl = <T>(
  f: PopArgument<StateCreator<T, [], []>>,
  name?: string
) => PopArgument<StateCreator<T, [], []>>;

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
  type T = ReturnType<typeof f>;
  const loggedSet: typeof set = (...a) => {
    const storeName = name ? `${name}` : "unamed";
    console.debug(
      `%c Store update `,
      "background: #222; color: #FFC0CB",
      storeName
    );
    const initialState = get();
    set(...a);
    console.debug({
      storeName,
      initialState,
      newState: get(),
      delta: a[0],
    });
  };
  store.setState = loggedSet;

  return f(loggedSet, get, store);
};

type PopArgument<T extends (...a: never[]) => unknown> = T extends (
  ...a: [...infer A, infer _]
) => infer R
  ? (...a: A) => R
  : never;

export const logger = (loggerImpl as unknown) as Logger;
