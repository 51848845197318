import create from "zustand";
import { initialState, StoreState } from "./state";
import { logger } from "./middleware";

type Store = StoreState;

export const useReservationStore = create<Store>(
  logger(
    (set, get) => ({
      ...initialState,
    }),
    "reservation-store"
  )
);

export const getState = useReservationStore.getState;
export const setState = useReservationStore.setState;

export * from "./state";
